.login {
    width: 50%;
    background: #fff;
    padding: 20px;
    margin: auto;
    border-radius: 20px;
    border: 1px solid #eee;
}
.login h1 {
    text-align: center;
    margin-top: 0;
}

.login img {
    width: 250px;
    display: block;
    margin: auto;
}
.login form {
    text-align: right;
}
.login form input {
    width: calc(100% - 42px);
}
.login form button {
    margin-top: 32px;
    margin-left: 16px;
}

@media only screen and (max-width: 768px) {
    .login img {
        width: 170px;
    }

    .app.login {
        width: 80%;
    }

    .login .actions-footer {
        display: flex;
        flex-direction: column-reverse;
    }
    .login .actions-footer > a,
    .login .actions-footer > button {
        text-decoration: none;
        display: inline-block;
    }
    .login .actions-footer button {
        margin: 16px auto;
        width: 100%;
    }
}
