.testimonials {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.testimonials .testimonial {
    border-bottom: 1px solid #eee;
    margin-bottom: 32px;
    padding: 8px 8px 32px 8px;
    width: 40%;
}
.testimonials .testimonial input,
.testimonials .testimonial textarea {
    width: calc(100% - 24px);
    margin-bottom: 8px;
}
.testimonials .testimonial button {
    border: none;
    background: none;
    cursor: pointer;
    padding-left: 0;
}
.testimonials .testimonial button:hover {
    text-decoration: underline;
}


@media only screen and (max-width: 768px) {
    .testimonials .testimonial {
        width: 100%;
    }
}
