.login {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 20px;
  width: 50%;
  margin: auto;
  padding: 20px;
}

.login h1 {
  text-align: center;
  margin-top: 0;
}

.login img {
  width: 250px;
  margin: auto;
  display: block;
}

.login form {
  text-align: right;
}

.login form input {
  width: calc(100% - 42px);
}

.login form button {
  margin-top: 32px;
  margin-left: 16px;
}

@media only screen and (width <= 768px) {
  .login img {
    width: 170px;
  }

  .app.login {
    width: 80%;
  }

  .login .actions-footer {
    flex-direction: column-reverse;
    display: flex;
  }

  .login .actions-footer > a, .login .actions-footer > button {
    text-decoration: none;
    display: inline-block;
  }

  .login .actions-footer button {
    width: 100%;
    margin: 16px auto;
  }
}

input[type="file"] {
  border: none;
  padding-left: 0;
}

.photos-container {
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 32px;
  display: flex;
}

.photos-container .photo {
  margin-bottom: 32px;
}

.photos-container .photo img, .photos-container .photo input, .photos-container .photo button {
  display: block;
}

.photos-container .photo button {
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 6px;
  padding-left: 0;
}

.photos-container .photo button:hover {
  text-decoration: underline;
}

.photos-container .photo input {
  width: calc(100% - 42px);
}

.photos-container .image-preview {
  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
  overflow: hidden;
}

.testimonials {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
  display: flex;
}

.testimonials .testimonial {
  border-bottom: 1px solid #eee;
  width: 40%;
  margin-bottom: 32px;
  padding: 8px 8px 32px;
}

.testimonials .testimonial input, .testimonials .testimonial textarea {
  width: calc(100% - 24px);
  margin-bottom: 8px;
}

.testimonials .testimonial button {
  cursor: pointer;
  background: none;
  border: none;
  padding-left: 0;
}

.testimonials .testimonial button:hover {
  text-decoration: underline;
}

@media only screen and (width <= 768px) {
  .testimonials .testimonial {
    width: 100%;
  }
}

/*# sourceMappingURL=index.4064cb6f.css.map */
