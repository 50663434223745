.photos-container {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    flex-wrap: wrap;
}

.photos-container .photo {
    margin-bottom: 32px;
}
.photos-container .photo img,
.photos-container .photo input,
.photos-container .photo button {
    display: block;
}
.photos-container .photo button {
    margin-top: 6px;
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 0;
}
.photos-container .photo button:hover {
    text-decoration: underline;
}
.photos-container .photo input {
    width: calc(100% - 42px);
}
.photos-container .image-preview {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #eee;
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
}
